import { useQuery, UseQueryResult } from "react-query";
import {
  multiClassTimeSlotsAllPOST,
  TimeSlot,
  useAllBookedMeetings,
  useBookMeetingMutation,
} from "./api";
import { groupBy } from "lodash";
import { Section } from "./components";
import { dayToStringShort, dateToShortTime } from "./lib";
import CheckIcon from "@mui/icons-material/Check";
import { LoadingDots } from "./LoadingDots";
import { BookButton } from "./BookButton";
import { PaymentMethodsInfo } from "./QuickBook";
import penguin from "./penguin_money.gif";
import { atom, useAtom } from "jotai";
import { useState } from "react";
import psstPenguin from "./penguin_psst";
import {
  useWrapped,
  useWrapped2024StepAtom,
  useWrappedEnabled,
} from "./wrapped2024/Wrapped2024";
import { Z_INDEXES } from "./constants";

function useScheduleData(): UseQueryResult<TimeSlot[]> & {
  usingCached: boolean;
} {
  // TODO: at some point just drop the "realResult"... we currently need it to
  // check reservations though
  const realResult = useQuery("multiTimeSlot", async () =>
    multiClassTimeSlotsAllPOST()
  );

  return Object.assign({}, realResult, { usingCached: false });

  // TODO: disabling this because Halix caught onto us... maybe try again in a
  // month under a different set of names
  // const cachedResult = useQuery("multiTimeSlotCached", async () =>
  //   multiTimeSlotCachedGET()
  // );

  // return useMemo(() => {
  //   if (realResult.status !== "success" && cachedResult.data) {
  //     return Object.assign({}, cachedResult, { usingCached: true });
  //   } else {
  //     return Object.assign({}, realResult, { usingCached: false });
  //   }
  // }, [cachedResult, realResult]);
}

export const scheduleExpandedDateAtom = atom<string | null>(null);
export function Schedule() {
  const { data: bookedData, isFetching: bookedIsFetching } =
    useAllBookedMeetings();
  const { data, status, isFetching, usingCached } = useScheduleData();
  const [expandedDateStr, setExpandedDate] = useAtom(scheduleExpandedDateAtom);
  const TO_SHOW = 6;
  const [showNum, setShowNum] = useState(TO_SHOW);
  const slots = data || [];
  const slotsByDay = groupBy(slots, (slot) => slot.dateOnly.toDateString());
  const expandedDate: Date | undefined =
    slotsByDay[expandedDateStr || ""]?.[0]?.dateOnly;
  const headerString = expandedDate
    ? `← booking ${
        dayToStringShort[expandedDate.getDay()]
      } ${expandedDate.getDate()}`
    : "upcoming crowdedness";
  const wrappedEnabled = useWrappedEnabled();
  const wrappedResult = useWrapped(wrappedEnabled);

  return (
    <Section>
      <h2
        className="text-slate-600 w-full text-lg mb-4"
        onClick={() => setExpandedDate(null)}
      >
        {headerString}
        {(isFetching || usingCached || bookedIsFetching) && <LoadingDots />}
      </h2>
      {status !== "success" ? (
        <div>loading...</div>
      ) : expandedDateStr ? (
        <DaySlotSingle
          slots={slotsByDay[expandedDateStr]}
          bookedMeetingKeys={bookedData}
        />
      ) : (
        <div>
          <div className="w-full grid grid-cols-2 gap-4 max-w-sm">
            {Object.keys(slotsByDay)
              .slice(0, showNum)
              .map((date, i) => {
                return (
                  <DaySlots
                    bookedMeetingKeys={bookedData}
                    key={date}
                    slots={slotsByDay[date]}
                    onClick={() => setExpandedDate(date)}
                    showWrappedEntrypoint={
                      i === 1 &&
                      wrappedEnabled &&
                      wrappedResult !== undefined &&
                      wrappedResult.length > 0
                    }
                  />
                );
              })}
          </div>
          {!usingCached && showNum < Object.keys(slotsByDay).length && (
            <div className="flex flex-row justify-center">
              <button
                className="mt-5 text-slate-500 text-sm underline decoration-dotted"
                onClick={() => setShowNum((n) => n + TO_SHOW)}
              >
                show more
              </button>
            </div>
          )}
        </div>
      )}
    </Section>
  );
}

function DaySlotSingle(props: {
  slots: TimeSlot[];
  bookedMeetingKeys: string[] | undefined;
}) {
  const slots = props.slots;
  const bookMutation = useBookMeetingMutation();

  return (
    <div className="bg-amber-100 p-6 w-full">
      {bookMutation.isLoading ? (
        <div className="flex flex-col items-center justify-center h-24">
          <img
            className="w-20 h-20 opacity-80 rounded"
            src={penguin}
            alt="penguin money"
          />
        </div>
      ) : bookMutation.isSuccess ? (
        <div className="flex flex-col items-center justify-center h-24 text-lg">
          <div>successfully booked! 🎉</div>
          <PaymentMethodsInfo paymentMethods={bookMutation.data} />
        </div>
      ) : (
        <div
          className="text-xl justify-center items-center"
          style={{
            display: "grid",
            gridTemplateColumns: "4fr 2fr 2fr",
            rowGap: "20px",
            columnGap: "4px",
          }}
        >
          {slots.map((slot) => (
            <div className="contents" key={slot.dateTime.toString()}>
              <span className="col-start-1 text-2xl">
                {props.bookedMeetingKeys?.includes(slot.meetingKey) && (
                  <div className="absolute -ml-[20px] -mt-[-6px]">
                    <CheckIcon color="success" fontSize="small" />
                  </div>
                )}
                <BookButton
                  key={dateToShortTime(slot.dateTime)}
                  time={dateToShortTime(slot.dateTime)}
                  date={slot.dateTime}
                  disabled={bookMutation.isLoading}
                  book={async (args) => {
                    await bookMutation.mutateAsync(args);
                  }}
                  smaller
                />
              </span>
              <span className={"col-start-2"}>{slot.durationMins}min</span>
              <span className={"col-start-3 justify-self-end"}>
                {slot.currentEnrollment}⛸
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function DaySlots(props: {
  slots: TimeSlot[];
  bookedMeetingKeys: string[] | undefined;
  onClick: () => void;
  showWrappedEntrypoint?: boolean;
}) {
  const date = props.slots[0].dateOnly;
  const dateStr = `${dayToStringShort[date.getDay()]} ${date.getDate()}`;
  const bookedMeetingKeys = props.bookedMeetingKeys || [];
  const [, setWrapped2024Step] = useWrapped2024StepAtom();
  return (
    <div className="relative">
      {props.showWrappedEntrypoint && (
        <div
          className={`absolute top-[-2.95rem] right-[-2.4em] ${Z_INDEXES.PSST_PENGUIN} rotate-[10deg] p-4`}
          onClick={() => setWrapped2024Step(0)}
        >
          {psstPenguin}
        </div>
      )}
      <div
        className={`h-full bg-amber-100 p-5 relative ${Z_INDEXES.DAY_SLOT}`}
        onClick={props.onClick}
      >
        <div className="text-lg xs:text-xl font-normal">{dateStr}</div>
        {props.slots.map((slot) => (
          <div
            key={slot.dateTime.toString()}
            className="text-base xs:text-lg text-justify flex flex-row"
          >
            <span className="flex-auto flex flex-row items-center mr-4">
              {bookedMeetingKeys.includes(slot.meetingKey) && (
                <div className="absolute -ml-[18px] -mt-[3px]">
                  <CheckIcon color="success" fontSize="inherit" />
                </div>
              )}
              {dateToShortTime(slot.dateTime)}
              {/* Indicate anything >60mins */}
              {slot.durationMins > 60 && "*"}
            </span>
            <span className="flex-auto flex flex-row-reverse">
              {slot.currentEnrollment}⛸
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
