export async function get<T = any>(
  url: string,
  query: { [key: string]: string },
  bearerToken?: string
): Promise<T> {
  const response = await fetch(url + "?" + new URLSearchParams(query), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
  });
  const json = await response.json();
  if (response.status >= 400 && response.status < 500) {
    throw new Error("Auth error");
  }
  return json;
}

export async function post<T = any>(
  url: string,
  query: { [key: string]: string },
  body: object,
  bearerToken?: string,
  // temp ugly api
  noCors?: boolean
): Promise<T> {
  const response = await fetch(url + "?" + new URLSearchParams(query), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify(body),
    mode: noCors ? "no-cors" : undefined,
  });
  const json = await response.json();
  if (response.status >= 400 && response.status < 500) {
    throw new Error("Auth error");
  }
  return json;
}
